import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentNode, FieldNode, Kind, OperationDefinitionNode, print } from 'graphql';
import { Observable, map } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomGraphQLError } from '../utils/graphql-error';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar
  ) {}

  public multipart<T, V = { [key: string]: any }>({
    variables,
    query,
    file
  }: {
    query: DocumentNode;
    variables?: V;
    file: FormData;
  }): Observable<T> {
    const form = file;
    form.append('query', print(query));
    form.append('variables', JSON.stringify(variables));
    return this.http.post<{ data?: T; errors?: any }>(`/graphql`, form, { headers: { multipart: 'true' } }).pipe(
      map(response => {
        const operationDefinitionNode = query.definitions.find(
          def => def.kind === Kind.OPERATION_DEFINITION
        ) as OperationDefinitionNode;
        const queryNames = operationDefinitionNode.selectionSet.selections.map(
          (selection: FieldNode) => selection.name.value
        );
        if (response.errors) {
          const error = new Error(JSON.stringify(response.errors));
          this.snackbar.open('Server Error Occurred', null, { duration: 5000, panelClass: 'error-toast' });
          Sentry.captureException(error);
          throw error;
        } else if (queryNames.some(name => response.data[name].errors && response.data[name].errors.length > 0)) {
          const error = new Error(JSON.stringify(queryNames.map(queryName => response.data[queryName].errors)));
          Sentry.captureException(error);
          throw error;
        }
        return queryNames.reduce((accum, queryName) => ({ ...accum, ...response.data[queryName] }), {} as T);
      })
    );
  }

  public request<T, V = { [key: string]: any }>({
    variables,
    query,
    options = {
      headers: {}
    }
  }: {
    query: DocumentNode;
    variables?: V;
    options?: {
      headers: { [key: string]: string };
    };
  }): Observable<T> {
    return this.http
      .post<{ data?: T; errors?: any }>(`/graphql`, { query: print(query), variables }, { headers: options.headers })
      .pipe(
        map(response => {
          const operationDefinitionNode = query.definitions.find(
            def => def.kind === Kind.OPERATION_DEFINITION
          ) as OperationDefinitionNode;
          const primaryQueryName = (operationDefinitionNode.selectionSet.selections[0] as FieldNode).name.value;
          if (response.errors) {
            const error = new Error(JSON.stringify(response.errors));
            this.snackbar.open('Server Error Occurred', null, { duration: 5000, panelClass: 'error-toast' });
            Sentry.captureException(error);
            throw error;
          }
          return response.data[primaryQueryName];
        })
      );
  }

  public mutate<R, P = { [key: string]: any }>({
    variables,
    query,
    options = {
      headers: {}
    }
  }: {
    query: DocumentNode;
    variables?: P;
    options?: {
      headers: { [key: string]: string };
    };
  }): Observable<R> {
    return this.http
      .post<{ data?: R; errors?: any }>(
        `/graphql`,
        {
          ...options,
          query: print(query),
          variables
        },
        { headers: options.headers }
      )
      .pipe(
        map(response => {
          const operationDefinitionNode = query.definitions.find(
            def => def.kind === Kind.OPERATION_DEFINITION
          ) as OperationDefinitionNode;
          const queryName = (operationDefinitionNode.selectionSet.selections[0] as FieldNode).name.value;
          if (response.errors && response.errors.length > 0) {
            throw new CustomGraphQLError('GraphQL Error', response.errors);
          } else if (
            response.data[queryName] &&
            response.data[queryName].errors &&
            response.data[queryName].errors.length > 0
          ) {
            throw new CustomGraphQLError('GraphQL Error', response.data[queryName].errors);
          } else {
            return response.data[queryName];
          }
        })
      );
  }
}
